import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { Link, Stack, Alert, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { DataService } from '../../../components/DataService'
import ChangePasswordDialog from '../../../components/changePassword'
import InputMask from "react-input-mask";

// ----------------------------------------------------------------------

export default function LoginForm({ t }) {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(null)
  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required(t("Phone_Required")),
    password: Yup.string().required(t("Password_Required")),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      if (values.phone.includes("_")) {
        formik.setFieldError('phone', t("Phone_Invalid"))
        setSubmitting(false);
        return;
      }

      var res = await DataService.post(`Users/LoginWithPhone`, values);
      if (res.data.success) {
        setShowOTPForm(true);
        formik1.setFieldValue('phone', values.phone)
        enqueueSnackbar(t("OTP_Success"));
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } else if (res.data.success == false) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: t(res.data.errorCode) });
        }
      }
    },
  });

  async function handlePasswordChangeSuccess(d){
    setOpenChangePasswordDialog(false);
    await login({ phone: d.user.phone, name: d.user.name, role: d.user.roles[0].role, company: d.user.roles[0].company? d.user.roles[0].company.name : "" }, d.token);
    enqueueSnackbar(t("Login_Success"));  
  }

  const OTPSchema = Yup.object().shape({
    otp: Yup.string().required(t("OTP_Required")),
  });

  const formik1 = useFormik({
    initialValues: {
      phone: '',
      otp: '',
    },
    validationSchema: OTPSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {

      if (values.otp.includes("_")) {
        formik1.setFieldError('otp', t("OTP_Invalid"))
        setSubmitting(false);
        return;
      }
      var res = await DataService.post(`Users/VerifyOTP`, values);
      if (res.data.success) {
        var d = res.data.data;
        if(d.user.requiredNewPassword == true){
          setOpenChangePasswordDialog(d);
        }else{
          await login({ phone: d.user.phone, name: d.user.name, role: d.user.roles[0].role, company: d.user.roles[0].company? d.user.roles[0].company.name : "" }, d.token);
          enqueueSnackbar(t("Login_Success"));  
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } else if (res.data.success == false) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: t(res.data.errorCode) });
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      {openChangePasswordDialog && <ChangePasswordDialog handleSuccess={(d) => handlePasswordChangeSuccess(d)} close={() => {}} showCloseButton={false} data={openChangePasswordDialog} />}
      {showOTPForm == false ? (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <InputMask
                fullWidth
                maskChar={null}
                mask="9999999999"
                label={t("Phone")}
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone ? errors.phone : t('Phone_Info')}

              >
                {(inputProps) => (
                  <TextField {...inputProps} />
                )}
              </InputMask>

              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t("Password")}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <br />
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
          <div>

          </div>
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            {t('Forget_Password')}
          </Link>
        </Stack> */}

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              {t('Login')}
            </LoadingButton>
          </Form>
        </FormikProvider>
      ) : (

        <FormikProvider value={formik1}>
          <Form autoComplete="off" noValidate onSubmit={formik1.handleSubmit}>
            <Stack spacing={3}>
              {formik1.errors.afterSubmit && <Alert severity="error">{formik1.errors.afterSubmit}</Alert>}

              <InputMask
                fullWidth
                autoFocus={false}
                mask="0599999999"
                label={t("Phone")}
                disabled
                value={formik1.values.phone}
              >
                {(inputProps) => (
                  <TextField {...inputProps} />
                )}
              </InputMask>
              <InputMask
                fullWidth
                autoFocus
                mask="99999999"
                label={t("OTP")}
                {...formik1.getFieldProps('otp')}
                error={Boolean(formik1.touched.otp && formik1.errors.otp)}
                helperText={formik1.touched.otp && formik1.errors.otp}
              >
                {(inputProps) => (
                  <TextField {...inputProps} />
                )}
              </InputMask>
            </Stack>
            <br />
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={formik1.isSubmitting}>
              {t('Verify')}
            </LoadingButton>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
