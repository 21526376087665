

import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
// import Backdrop from "@material-ui/core/Backdrop";
// import { PropTypes } from 'prop-types';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, Dialog, Grid } from '@mui/material';
import {API_ENDPOINT} from '../../config'
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/useAuth';
//import Loader from "dan-images/loader.gif"
//const API_ENDPOINT = "hhh"

const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
});
const ax = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Content-Type": "application/json",
  },
});

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation(['translation']);
  const interceptors = useMemo(() => {
    const inc = () => {
      // document.getElementById("loader-startTime").innerText = Date.now();
      // setCounter(counter => counter + 1);
    }
    const dec = () => {
      // var startTime = document.getElementById("loader-startTime").innerText;
      // var millis = Date.now() - startTime;
      // if (millis > 3000) {
      //   setCounter(counter => counter - 1);
      // } else {
      //   setTimeout(() => {
      //     setCounter(counter => counter - 1);
      //   }, 3000 - millis);
      // }	

    }
    function handleLogOut() {
      enqueueSnackbar(t('Unauthorized_Message'), {variant:"error"});
      logout()
    }
    return ({
      request: config => (inc(), config),
      response: response => (dec(), response),
      error: error => {

        dec()
        const { response } = error;
        if (response) {
          if (response.status === 400) {
            return response;
          } else if (response.status === 401) {     
            handleLogOut();
          } else if (response.status === 402) {
            enqueueSnackbar(t('Demo_Message'), {variant:"error"});
          } else if (response.status === 403) {
            handleLogOut();
          } else {
            enqueueSnackbar(t('Error'), {variant:"error"});
            return error;
          }
        } else {
          enqueueSnackbar(t('Error'), {variant:"error"});
        }
        return Promise.reject(error);
      },
    });
  }, []); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = ax.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor = ax.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      ax.interceptors.request.eject(reqInterceptor);
      ax.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};

function DataServiceLoading(props) {
  const [loading] = useAxiosLoader();

  return (
    <Dialog PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }} open={loading}>
      {/* <img height="150" src={Loader} /> */}
    </Dialog>
  );
}

class DataService {
  static get(path = "") {
    return ax({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static getFile(path = "") {
    return ax({
      method: "GET",
      url: path,
      responseType: "blob",
      headers: { ...authHeader() },
    });
  }
  static postFile(path = "", data = {}) {
    return ax({
      method: "POST",
      url: path,
      data,
      responseType: "blob",
      headers: { ...authHeader() },
    });
  }
  static post(path = "", data = {}, optionalHeader = {}) {
    return ax({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return ax({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "", data = {}) {
    return ax({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return ax({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

export default DataServiceLoading;
export { DataService }




// const useStyles = makeStyles((theme) =>
//   createStyles({
//     circularProgress: {
//       position: 'fixed',
//       top: 'calc(50% - 45px)',
//       left: 'calc(50% - 45px)',
//     },
//     backdrop: {
//       zIndex: 99999,
//       color: "#fff",
//     },
//   })
// );