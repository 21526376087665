import * as Yup from 'yup';
import { Dialog, Box, Grid, Card, TextField, Stack, Alert, Button, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { DataService } from '../DataService';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {API_ENDPOINT} from '../../config'
export default function ChangePassword({ close, handleSuccess, showCloseButton = true, data = null }) {
  const { t } = useTranslation("Change_Password")
  const { enqueueSnackbar } = useSnackbar();
  const NewSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('Current_Password_Required')),
    newPassword: Yup.string().min(8, t('New_Password_Min')).required(t('New_Password_Required')),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], t('Confirm_New_Password_NotMatch')).required(t('Confirm_New_Password_Required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: NewSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      var token = localStorage.getItem("accessToken");
      if(data != null){
        token = data.token;
      }
      var uri = encodeURI(`${API_ENDPOINT}Users/ChangePassword`)
      const headers = { Authorization: `Bearer ${token}` };
      axios.put(uri, values,{headers} )
      .then(function (res) {
        if (res.data.success) {
          enqueueSnackbar(t("Password_Changed_Success"));
          handleSuccess(data);
          close();
        } else if (res.data.success == false) {
          if(res.data.errorCode == 1006){
            formik.setFieldError("currentPassword",t('Invalid_Password'))
          }else{
            setErrors({ afterSubmit: res.data.message });
          }
        }
      })
      .catch(function (error) {
        console.log(error)
        if(error.response){
          if(error.response.data){
            var data = error.response.data;
            if(data.errorCode == 1006){
              formik.setFieldError("currentPassword",t('Invalid_Password'))
            }else{
              setErrors({ afterSubmit: data.message });
            }
          }
        }
      });      
    },
  });
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, resetForm } = formik;


  return (
    <Dialog fullWidth open>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <h2>{t('Title')}</h2>
                  </div>
                </Box>

                <br />
                <Stack spacing={3}>
                  {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="password"
                      label={t("Current_Password")}
                      {...getFieldProps('currentPassword')}
                      error={Boolean(touched.currentPassword && errors.currentPassword)}
                      helperText={touched.currentPassword && errors.currentPassword}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      type="password"
                      fullWidth
                      label={t("New_Password")}
                      {...getFieldProps('newPassword')}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="password"
                      label={t("Confirm_New_Password")}
                      {...getFieldProps('confirmNewPassword')}
                      error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                      helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    {showCloseButton &&
                      <Button sx={{ mr: 1 }} variant="outlined" onClick={() => close()}>
                        {t('Close')}
                      </Button>}
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {t('Change')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
