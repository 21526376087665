import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
//
import enLocales from './en.json';
import heLocales from './he.json';

import deLocales from './de.json';
import frLocales from './fr.json';

// ----------------------------------------------------------------------
const resources = {
  en: enLocales,
  he: heLocales
  //he: he
};
i18n
  //.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: localStorage.getItem("i18nextLng") || "he",
    resources,
   // fallbackLng: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')? 'en':'he',
    fallbackLng: 'he',
    keySeparator: '.',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  // .use(initReactI18next)
  // .init({
  //   resources: {
  //     en: enLocales,
  //     de: { translations: deLocales },
  //     fr: { translations: frLocales }
  //   },
  //   lng: localStorage.getItem('i18nextLng') || 'en',
  //   fallbackLng: 'en',
  //   debug: false,
  //   keySeparator: '.',
  //   // ns: ['translations'],
  //   defaultNS: 'translations',
  //   // interpolation: {
  //   //   escapeValue: false
  //   // }
  // });

export default i18n;
