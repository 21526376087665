import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSection.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isCollapse = false, ...other }) {
  const { user } = useAuth();
  const {role = 2} = user;
  const {t} = useTranslation("NavBar")
  const newNavConfig = navConfig.map(x=> {
    x.subheader = t(x.subheader)
    x.items = x.items.map(i=>{
      i.title = t(i.title)
      return i;
    })
    return x;
  });
  return (
    <Box {...other}>
      {newNavConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>
          
          {group.items.filter(x=>x.role.includes(role)).map((list) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
         {/* {role==1 && 
           group.items.filter(x=>x.forAdmin == true).map((list) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))} */}
        </List>
      ))}
    </Box>
  );
}
